import { CheckIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React from 'react';

export interface CheckboxProps {
  disabled?: boolean;
  checked?: boolean;
  onChange?: (val: boolean) => void;
  name?: string;
  size?: 'small' | 'default';
  checkBoxVariant?: CheckboxVariant;
  'aria-label': string;
}

enum CheckboxVariant {
  'Default' = 'bg-brandMidnight',
  'Turquoise' = 'bg-turquoise',
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  onChange = () => {},
  checkBoxVariant = CheckboxVariant.Default,
  size = 'default',
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <section
      aria-label={ariaLabel}
      className={clsx('flex items-center', {
        'cursor-pointer': !props.disabled,
      })}
      onClick={() => onChange(!checked)}
    >
      <input
        className="hidden"
        type="checkbox"
        checked={checked}
        onChange={(evt) => onChange(evt.currentTarget.checked)}
        {...props}
      />
      <div
        className={clsx(
          'flex items-center justify-center rounded-xsmall border-2',
          {
            'border-transparent': checked,
            [checkBoxVariant]: checked,
            'border-gray-300 bg-white': !checked,
            'size-[20px]': size === 'default',
            'size-l': size === 'small',
          }
        )}
      >
        <CheckIcon stroke="white" width={26} height={26} />
      </div>
    </section>
  );
};
