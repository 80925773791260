import { CompareCTACard } from '@/components/molecules/CompareCTACard/CompareCTACard';
import { CustomVehicleCard } from '@/components/molecules/CustomVehicleCard';
import { TrimStyle } from '@/lib/schema/build/types';
import { FC } from 'react';

const insertCompare = (list: TrimStyle[]) => {
  const cardList = list.map((detail) => (
    <div className="min-w-[280px]" key={detail.trimId}>
      <CustomVehicleCard vehicleDetails={detail} />
    </div>
  ));
  return [
    cardList[0],
    <div className="flex min-w-[280px]" key="compare">
      <CompareCTACard />
    </div>,
    ...cardList.slice(1),
  ];
};

export const HomePageShopNowCard: FC<{
  vehicles: TrimStyle[];
}> = ({ vehicles }) => {
  return (
    <div className={'flex w-full flex-col'}>
      <div className="inline-flex flex-col items-center justify-start gap-10 bg-background-light p-[20px] py-[40px] md:p-[40px] lg:px-[150px] lg:py-[100px]">
        <div className="inline-flex flex-col items-center justify-start gap-3">
          <div className="text-center text-title24px font-semibold md:text-display48px">
            Shop now & save
          </div>
          <div className="text-center text-base font-light leading-snug tracking-tight text-neutral-800 l:w-[644px]">
            Explore the cost of ownership across all electric vehicles
          </div>
        </div>

        <div className="hide-scrollbar flex w-full gap-5 overflow-x-scroll l:grid l:grid-cols-3 lg:max-w-[1920px] xl:grid-cols-4">
          {insertCompare(vehicles.slice(1, 8))}
        </div>
      </div>
    </div>
  );
};
